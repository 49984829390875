import { createRouter, createWebHistory } from 'vue-router'

import home from '../pages/home/index.vue'
import stars from '../pages/game/startGame.vue'
import mark from '../pages/game/mark6.vue';
import lucky from '../pages/game/lucky3d.vue';
import texas from '../pages/game/texas.vue';
import zodiacgo from '../pages/game/zodiacgo.vue';
import slotlotto from '../pages/game/slotlotto.vue';

// import gameLayout from '../pages/game/gameLayout.vue'
// import container from '../pages/game/container.vue'
// import texas from '../pages/game/Texas.vue'
const routes = [
  {
    path: '/',
    name: 'index',
    component: home
  },
  {
    path: '/5stars',
    name: '5stars',
    component: stars
  },
  {
    path: '/mark6',
    name: 'mark6',
    component: mark
  },
  {
    path: '/lucky3d',
    name: 'lucky3d',
    component: lucky
  },
  {
    path: '/texas',
    name: 'texas',
    component: texas
  },
  {
    path: '/zodiacgo',
    name: 'zodiacgo',
    component: zodiacgo
  },
  {
    path: '/slotlotto',
    name: 'slotlotto',
    component: slotlotto
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
