<template>
	<div class="page flex flex-col w-full h-full pt-1" style="background-color: #1a1a1a">
		<div class="overflow-auto h-full w-full min-h-full ssc-history-sum" ref="scrollContainer" id="nav">
			<table class="min-w-full border-collapse min-h-full table-auto relative">
				<thead class="sticky top-0 z-50 bg-[#1a1a1a] ">
					<tr class="flex top-0 mb-1">
						<!-- 固定的表头行 -->
						<th class="tr-line1">
							<span class="th-span">期号</span>
						</th>
						<th class="tr-line2">
							<span class="th-span">中奖结果</span>
						</th>
						<th class="th-header" v-for="n in typeNumber">
							<span class="th-span">{{ n - 1 }}</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<block class="block list-centent">
						<tr class="flex mt-1" v-for="(item, key) in list">
							<td class="tr-line1">
								<span class="th-span">{{ issue[key] }}</span>
							</td>
							<td class="tr-line2 gap-0.5 flex">
								<span
									class="inline-block w-5 h-5 text-center text-sm leading-5 font-semibold rounded-xl bg-[#2fbcff]"
									v-for="(i, idx) in winningNumbers[key]" :key="idx"
									:class="i>=5 ? 'bg-[#ff2fbc]':'bg-[#2fbcff]' ">{{ i }}</span>
							</td>
							<td class="td-header" v-for="item in sumNumbers[key]">
								<span class="th-span-number" :class="
                    [isNumber(item) ? 'bg-[#424242] text-[#888]' : 'bg-[#2fbcff]',!isNumber(item) && item.replace('*','')>=Math.ceil(typeNumber/2) ? 'bg-[#ff2fbc]':'bg-[#2fbcff]']
                  ">{{ isNumber(item) ? item : item.replace('*','')  }}</span>
							</td>
						</tr>
					</block>
					<block class="block w-full sticky left-0 z-30 bottom-0 pt-0.5 bg-[#1a1a1a]">
						<tr class="flex mt-1">
							<td class="tr-line3" colspan="2">
								<span class="th-span">预选区</span>
							</td>
							<td class="td-header" v-for="i in preSelection">
								<span class="th-span-pre"
									:class="!isNumber(i) ?  i.replace('*','')>=Math.ceil(typeNumber/2) ? 'bg-[#ff2fbc]':'bg-[#2fbcff]':'bg-[#434343]'">{{ isNumber(i) ? i: i.replace('*','') }}</span>
							</td>
						</tr>
						<tr class="flex mt-1">
							<td class="tr-line3" colspan="2">
								<span class="th-span">出现总次数</span>
							</td>
							<td class="td-header" v-for="i in continuousOccurrence">
								<span class="th-span">{{ i }}</span>
							</td>
						</tr>
						<tr class="flex mt-1">
							<td class="tr-line3" colspan="2">
								<span class="th-span">连续出现(最大次数)</span>
							</td>
							<td class="td-header" v-for="i in consecutiveOccurrences">
								<span class="th-span">{{ i }}</span>
							</td>
						</tr>
						<tr class="flex mt-1">
							<td class="tr-line3" colspan="2">
								<span class="th-span">连续不出现(最大次数)</span>
							</td>
							<td class="td-header" v-for="i in discontinuousOccurrences">
								<span class="th-span">{{ i }}</span>
							</td>
						</tr>
					</block>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
	export default {
		props: ['list', 'type'],
		data() {
			return {
				list: [
					'5409100016-34586',
					'5409100017-54186',
					'5409100018-56096',
					'5409100019-66019',
					'5409100020-06522',
					'5409100021-45881',
					'5409100022-99095',
					'5409100023-82034',
					'5409100024-81040',
					'5409100025-72393',
					'5409100026-47935',
					'5409100027-11032',
					'5409100028-94813',
					'5409100029-54602',
					'5409100030-85670',
					'5409100031-72816',
					'5409100032-35525',
					'5409100033-38762',
					'5409100034-29893',
					'5409100035-25868',
					'5409100036-16875',
					'5409100037-13567',
					'5409100038-37363',
					'5409100039-43752',
					'5409100040-86830',
					'5409100041-50563',
					'5409100042-07000',
					'5409100043-72472',
					'5409100044-30525',
					'5409100045-92821',
					'5409100046-62317',
					'5409100047-59899',
					'5409100048-94543',
					'5409100049-68460',
					'5409100050-11357',
					'5409100051-75600',
					'5409100052-36337',
					'5409100053-97931',
					'5409100054-85593',
					'5409100055-18238',
					'5409100056-34097',
					'5409100057-67080',
					'5409100058-48948',
					'5409100059-64884',
					'5409100060-20316',
					'5409100061-48481',
					'5409100062-62568',
					'5409100063-53146',
					'5409100064-05210',
					'5409100065-25730',
					'5409100066-30249',
					'5409100067-53625',
					'5409100068-61377',
					'5409100069-37847',
					'5409100070-84987',
					'5409100071-93224',
					'5409100072-71598',
					'5409100073-21602',
					'5409100074-61234',
					'5409100075-28092',
					'5409100076-79555',
					'5409100077-02572',
					'5409100078-01982',
					'5409100079-98152',
					'5409100080-21216',
					'5409100081-90563',
					'5409100082-50461',
					'5409100083-72032',
					'5409100084-51981',
					'5409100085-89637',
					'5409100086-35729',
					'5409100087-40708',
					'5409100088-92232',
					'5409100089-98042',
					'5409100090-94375',
					'5409100091-21131',
					'5409100092-83190',
					'5409100093-47618',
					'5409100094-09306',
					'5409100095-12122',
					'5409100096-15210',
					'5409100097-40650',
					'5409100098-86983',
					'5409100099-77259',
					'5409100100-17705',
					'5409100101-56601',
					'5409100102-63730',
					'5409100103-75845',
					'5409100104-70544',
					'5409100105-64328',
					'5409100106-03636',
					'5409100107-52748',
					'5409100108-02993',
					'5409100109-01750',
					'5409100110-61148',
					'5409100111-75747',
					'5409100112-56237',
					'5409100113-49906',
					'5409100114-32117',
					'5409100115-31941',
				],
				setNumber: [], //单个号码
				issue: [], //期号
				winningNumbers: [], //开奖号码
				sumNumbers: [], //和值号码
				continuousOccurrence: [], //出现总次数
				consecutiveOccurrences: [], //连续出现最大值
				consecutiveOccurrencesData: [], //连续出现最大值暂存
				discontinuousOccurrences: [], //不连续出现最大值
				type: '3Star',
				typeNumber: 19,
				preSelection: [], //预选区
				scrollLeft:0
			};
		},
		mounted() {
			if (this.type == '3Star') {
				this.typeNumber = 28;
				for (let i = 0; i < 28; i++) {
					this.continuousOccurrence.push(0);
					this.consecutiveOccurrences.push(0);
					this.consecutiveOccurrencesData.push(0);
					this.discontinuousOccurrences.push(0);
				}
			} else {
				for (let i = 0; i < 19; i++) {
					this.continuousOccurrence.push(0);
					this.consecutiveOccurrences.push(0);
					this.consecutiveOccurrencesData.push(0);
					this.discontinuousOccurrences.push(0);
				}
			}
			this.initHistory(this.list);
			this.updatePreSelection();
			this.$nextTick(() => {
				this.scrollToBottom();
			});
			this.scrollInit();
		},
		methods: {
			scrollInit() {
				// 获取要绑定事件的元素
				const nav = document.getElementById("nav");
				console.log(6666666,nav);
				var flag; // 鼠标按下
				var downX; // 鼠标点击的x下标
				var scrollLeft; // 当前元素滚动条的偏移量
				nav.addEventListener("mousedown", function(event) {
					flag = true;
					downX = event.clientX; // 获取到点击的x下标
					scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
				});
				nav.addEventListener("mousemove", function(event) {
					if (flag) { // 判断是否是鼠标按下滚动元素区域
						var moveX = event.clientX; // 获取移动的x轴
						var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
						this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
						console.log(scrollX)
					}
				});
				// 鼠标抬起停止拖动
				nav.addEventListener("mouseup", function() {
					flag = false;
				});
				// 鼠标离开元素停止拖动
				nav.addEventListener("mouseleave", function(event) {
					flag = false;
				});
			},
			scrollToBottom() {
				const container = this.$refs.scrollContainer;
				container.scrollTop = container.scrollHeight;
			},
			initHistory(data) {
				for (let i = 0; i < data.length; i++) {
					let itemData = data[i];
					let jsonData = itemData.split('-');
					let preJsonData = i > 0 ? data[i - 1].split('-') : data[0].split('-'); // 上一条数据
					this.issue.push(jsonData[0]); // 期号
					let number = jsonData[1]; // 开奖号码
					this.winningNumbers.push(number);
					let sum = 0;
					let topSum = 0;
					if (this.type == '3Star') {
						sum = parseInt(number[0]) + parseInt(number[1]) + parseInt(number[2]);
						topSum =
							parseInt(preJsonData[1][0]) +
							parseInt(preJsonData[1][1]) +
							parseInt(preJsonData[1][2]);
					} else {
						sum = parseInt(number[0]) + parseInt(number[1]);
						topSum = parseInt(preJsonData[1][0]) + parseInt(preJsonData[1][1]);
					}
					let numberItem = [];

					for (let j = 0; j < this.typeNumber; j++) {
						if (j == sum) {
							numberItem.push('*' + sum);
							this.continuousOccurrence[j]++;
							//处理最大连续出现次数
							if (topSum == sum) {

								this.consecutiveOccurrencesData[j]++
								if (this.consecutiveOccurrencesData[j] > this.consecutiveOccurrences[j]) {
									this.consecutiveOccurrences[j] = this.consecutiveOccurrencesData[j]
								}
							} else {
								if (this.consecutiveOccurrences[j] == 0) {
									this.consecutiveOccurrences[j] = 1;
									this.consecutiveOccurrencesData[j] = 1;
								}
								if (this.consecutiveOccurrences[j] > 1) {
									this.consecutiveOccurrencesData[j] = 1;
								}
							}
						} else {
							let idx = 1;
							if (i > 0) {
								idx = this.sumNumbers[i - 1][j];
								idx = this.isNumber(idx) ? parseInt(idx) + 1 : 1;
								numberItem.push(idx); //this.sumNumbers[i-1][j]++
							} else {
								numberItem.push(idx);
							}
							//最大出现
							if (this.discontinuousOccurrences[j] < idx) {
								this.discontinuousOccurrences[j] = idx;
							}
						}
					}
					this.sumNumbers.push(numberItem);
				}
				this.initPreSelection();
				// console.log(this.consecutiveOccurrences);
				// console.log(this.discontinuousOccurrences);
			},
			initPreSelection() {
				let lastSumNumber = this.sumNumbers[this.sumNumbers.length - 1];
				for (let i = 0; i < lastSumNumber.length; i++) {
					this.preSelection.push(this.isNumber(lastSumNumber[i]) ? lastSumNumber[i] + 1 : 1);
				}
			},
			updatePreSelection(sum = [10, 15, 12]) {
				for (let i = 0; i < sum.length; i++) {
					console.log(sum[i])
					this.preSelection[sum[i]] = '*' + sum[i];
				}
			},
			isNumber(value) {
				return typeof value === 'number' && !isNaN(value);
			},
		},
	};
</script>