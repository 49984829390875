import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import "tailwindcss/tailwind.css";

import header from './components/header.vue'; // 导入组件

const app = createApp(App)
app.use(router)
app.mount('#app')

console.log(app);

app.component('headertop',header);