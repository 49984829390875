<template>
	<div class="page">
		<div class="container-box mx-auto aspect-auto h-screen 2xl:h-auto 2xl:aspect-[16/9]">
			<headertop></headertop>
			<div class="main-box">
				<!-- MD以上显示左侧菜单按钮 -->
				<div class="menu-box">
					<div class="game-time-box">
						50
					</div>
					<div class="username-box">
						HONEST
					</div>
					<div class="userMoney-box">
						<div class="unit-box">$</div>
						<div class="money-box">0.00</div>
					</div>
					<div class="game-menu">
						<div class="game-menu-item" @click='toggleGameType(0)'>大小</div>
						<div class="game-menu-item" @click='toggleGameType(1)'>单双</div>
						<div class="game-menu-item" @click='toggleGameType(2)'>和值</div>
						<div class="game-menu-item" @click='toggleGameType(3)'>星彩</div>
						<div class="game-menu-item" @click='toggleGameType(4)'>包号</div>
					</div>
					
					<div class="toggle-live">
						<div class="toggle-live-item" :class="menuType == 0 ? 'active' : '' " @click="menuType = 0">直播</div>
						<div class="toggle-live-item" :class="menuType == 1 ? 'active' : '' " @click="menuType = 1">历史</div>
					</div>
				</div>
				<!-- 正常游戏区域 -->
				<div class="center-box" v-if="menuType != 2">
					<div class="live-box" v-if="menuType == 0">
						<!-- <sscHistory1Vue></sscHistory1Vue> -->
					</div>
					<div class="history-box" v-if="menuType == 1">
						<sscHistory1Vue v-if="gameType == 0 || gameType == 1"></sscHistory1Vue>
						<sscHistory2Vue v-if="gameType == 2"></sscHistory2Vue>
						<sscHistory3Vue v-if="gameType == 3 || gameType == 4 "></sscHistory3Vue>
					</div>
					
					<div class="action-box-pc mt-[20px] hidden 2xl:flex">
						<!-- 通选 复选 -->
						<div class="check-box">
							<!-- <div class="check-row w-full">
								<div class="check-item w-full">通选</div>
							</div> -->
							<div class="check-row">
								<div class="check-item">1D</div>
								<div class="check-item">2D</div>
								<div class="check-item">3D</div>
								<div class="check-item">4D</div>
								<div class="check-item">5D</div>
							</div>
						</div>
						
						<!-- 投注预选区域 -->
						<div class="perselect-box 2xl:flex">
							<div class="perselect-item flex justify-center items-center text-[#ffffff]">
								<div class="">QUANTITY:</div>
								<div class="text-center text-[#BCFF2F] ml-[5px]">0</div>
							</div>
							<div class="perselect-item flex justify-center items-center text-[#ffffff]">
								<div class="">TICKET(S):</div>
								<div class="text-center text-[#BCFF2F] ml-[5px]">0</div>
							</div>
							<div class="perselect-item flex justify-center items-center text-[#ffffff]">
								<div class="">PRICE:</div>
								<div class="text-center text-[#BCFF2F] ml-[5px]">0</div>
							</div>
						</div>
						<div class="chip-box-pc 2xl:flex">
							<img class="chip-icon w-[37px] h-[25px]" src="@/assets/icons/Vector.png" alt="" />
							<div class="chip-item" v-for="item in 5">{{item}}</div>
						</div>
					</div>
					<div class="action-box">
						<div class="toggle-game">
							<div class="game-item" @click='toggleGameType(0)'>大小</div>
							<div class="game-item" @click='toggleGameType(1)'>单双</div>
							<div class="game-item" @click='toggleGameType(2)'>和值</div>
							<div class="game-item" @click='toggleGameType(3)'>星彩</div>
							<div class="game-item" @click='toggleGameType(4)'>包号</div>
						</div>
						<div class="check-box-md">
							<div class="check-row">
								<div class="check-item">通选</div>
								<div v-for="(item,index) in 5" @click="gameIndex = index" class="check-item" :class="gameIndex == index ? 'active' : '' ">
									<div class="radio"></div>
									<div>{{item}}D</div>
								</div>
							</div>
						</div>
						<!-- 赔率 -->
						<div class="game-detial 2xl:hidden">
							<div class="odds-box">
								<span class="mr-3">$2/TICKET</span>
								<span>TICKETS LIMT:20K</span>
							</div>
							<div class="money-box">
								$0.00
								<span class="icon size-5"></span>
							</div>
						</div>
						<!-- PC版 -->
						<div class="game-detial hidden 2xl:flex">
							<div class="odds-box">
								<span class="mr-3">$2/TICKET</span>
							</div>
							<div class="odds-box">
								<span>TICKETS LIMT:20K</span>
							</div>
						</div>
						<!-- 操作 -->
						<div class="operate-box-pc">
							<div class="operate-item-box">
								RESET
							</div>
							<div class="operate-item-box">
								SET-
							</div>
							<div class="operate-item-box">
								SET+
							</div>
							<div class="operate-item-box">
								CONFI
							</div>
						</div>
						<div class="chip-box-md">
							<div class="chip-icon w-[37px] h-[25px]" style="border: 1px solid red;"></div>
							<div class="chip-item" v-for="item in 9">{{item}}</div>
						</div>
						<div class="clear-float"></div>
						
						<!-- 菜单按钮区域 -->
						<div v-if="gameType == 0" class="flex flex-col 2xl:flex-row 2xl:mt-[30px]">
							<div class="action-row-2 mb-4 2xl:mb-0 2xl:mx-[10px]" v-for="item in 5" >
								<div class="action-item-label">
									{{item}}D
								</div>
								<div class="flex-1 flex flex-row flex-wrap justify-evenly items-center 2xl:w-full 2xl:flex 2xl:flex-row 2xl:justify-between">
									<div class="action-item-box" v-for="item in 2">
										{{item == 1 ? '小' : '大'}}
									</div>
								</div>
								
							</div>
						</div>
						<div v-if="gameType == 1" class="flex flex-col 2xl:flex-row 2xl:mt-[30px]">
							<div class="action-row-2 mb-4 2xl:mb-0 2xl:mx-[10px]" v-for="item in 5" >
								<div class="action-item-label">
									{{item}}D
								</div>
								<div class="flex-1 flex flex-row flex-wrap justify-evenly items-center 2xl:w-full 2xl:flex 2xl:flex-row 2xl:justify-between">
									<div class="action-item-box" v-for="item in 2">
										{{item == 1 ? '单' : '双'}}
									</div>
								</div>
								
							</div>
						</div>
						<div v-if="gameType == 2" class="flex flex-col 2xl:flex-row">
							<div class="action-row-17">
								<div class="action-item-box" v-for="item in 17">
									{{item}}
								</div>
							</div>
						</div>
						<!-- <div v-if="gameType == 2" class="flex flex-col 2xl:flex-row">
							<div class="action-row-28">
								<div class="action-item-box" v-for="item in 28">
									{{item}}
								</div>
							</div>
						</div> -->
						
						<div v-if="gameType == 4" class="flex flex-col 2xl:flex-row">
							<div class="action-row-10-separate">
								<div class="action-item-box" v-for="item in 10">
									{{item}}
								</div>
							</div>
						</div>
						
						<div v-if="gameType == 3" class="flex flex-col 2xl:flex-row">
							<div v-for="item in 5" class="flex flex-nowrap items-center 2xl:flex-wrap 2xl:flex-1 2xl:mx-1">
								<div class="action-item-label h-[90px] mx-2 md:h-[140px] 2xl:h-[40px] 2xl:mx-0">
									{{item}}D
								</div>
								<div class="action-row-10">
									<div class="action-item-box" v-for="item in 10">
										{{item}}
									</div>
								</div>
							</div>
						</div>
						
						<!-- 投注数量 -->
						<div class="chip-box mt-[10px]">
							<img class="chip-icon w-[25px] h-[17px]" src="@/assets/icons/Vector.png" alt="" />
							<div class="chip-item" @click="chipIndex = index" :class="chipIndex == index ? 'active' : '' " v-for="(item,index) in 8">{{item}}</div>
						</div>
						<div class="clear-float"></div>
						<!--下注按钮等-->
						<div class="operate-box">
							<div class="operate-item-box">
								RESET
							</div>
							<div class="operate-item-box">
								SET-
							</div>
							<div class="operate-item-box">
								SET+
							</div>
							<div class="operate-item-box">
								CONFI
							</div>
						</div>
						<!-- 投注预选区域 -->
						<div class="perselect-box xl:flex 2xl:hidden">
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">QUANTITY:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">0</div>
							</div>
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">TICKET(S):</div>
								<div class="flex-1 text-center text-[#BCFF2F]">0</div>
							</div>
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">PRICE:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">0</div>
							</div>
						</div>
						<div class="perselect-box xl:flex 2xl:hidden">
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1 ">HIT PRIZE:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">0</div>
							</div>
						</div>
						
						<div class="bet-all-box md:hidden">
							<div class="bet-all-title">CURRENT PURCHASE RECORD</div>
							<div class="bet-box">
								<div class="bet-item" v-for="item in 20">
									{{item}}
								</div>
							</div>
						</div>
					</div>
										
					<!-- <div class="bet-box">
						预选返奖区域
					</div> -->
				</div>
				<div class="bet-box md:hidden" v-if="menuType == 2">
					<div class="buy-history mt-[10px]">
						<div class="buy-history-title">已购彩票</div>
						<div class="buy-box">
							<div class="buy-item" v-for="item in 10">
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">1</div>
									<div class="buy-item-title-group-item">$100</div>
									<div class="buy-item-title-group-item">$999</div>
								</div>
							</div>
							
							<div class="buy-item">
								<div class="buy-item-title">购彩内容</div>
								<div class="buy-item-name-box">
									<div class="buy-item-name-item">购彩总数</div>
									<div class="buy-item-name-item">总价</div>
									<div class="buy-item-name-item">奖金最大</div>
								</div>
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">购彩总数</div>
									<div class="buy-item-title-group-item">总价</div>
								</div>
								<div class="buy-item-content-group">
									<div class="buy-item-content-group-item">100</div>
									<div class="buy-item-content-group-item">$999</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<!-- 历史记录区域 -->
				<div class="right-box">
					<div class="bet-all-box">
						<div class="bet-all-title">CURRENT PURCHASE RECORD</div>
						<div class="bet-box">
							<div class="bet-item" v-for="item in 20">
								{{item}}
							</div>
						</div>
					</div>
					<div class="buy-history">
						<div class="buy-history-title">已购彩票</div>
						<div class="buy-box">
							<div class="buy-item" v-for="item in 10">
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">1</div>
									<div class="buy-item-title-group-item">$100</div>
									<div class="buy-item-title-group-item">$999</div>
								</div>
							</div>
							
							<div class="buy-item">
								<div class="buy-item-title">购彩内容</div>
								<div class="buy-item-name-box">
									<div class="buy-item-name-item">购彩总数</div>
									<div class="buy-item-name-item">总价</div>
									<div class="buy-item-name-item">奖金最大</div>
								</div>
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">购彩总数</div>
									<div class="buy-item-title-group-item">总价</div>
								</div>
								<div class="buy-item-content-group">
									<div class="buy-item-content-group-item">100</div>
									<div class="buy-item-content-group-item">$999</div>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="bet-box">
						预选返奖区域
					</div> -->
				</div>
				<div class="footer-slot">
				</div>
			</div>
			<div class="footer md:hidden">
				<div class="footer-item" :class="menuType == 0 ? 'selected' : '' " @click="menuType = 0">
					<div class="img-icon"></div>
					<div>直播</div>
				</div>
				<div class="footer-item" :class="menuType == 1 ? 'selected' : '' " @click="menuType = 1">
					<div class="img-icon"></div>
					<div>历史记录</div>
				</div>
				<div class="footer-item" :class="menuType == 2 ? 'selected' : '' " @click="menuType = 2">
					<div class="img-icon"></div>
					<div>投注记录</div>
				</div>
			</div>
			<div class="footer hidden md:flex 2xl:hidden">
				<div class="footer-item" :class="menuType == 0 ? 'selected' : '' " @click="menuType = 0">
					<div class="img-icon"></div>
					<div>直播</div>
				</div>
				<div class="footer-item" :class="menuType == 1 ? 'selected' : '' " @click="menuType = 1">
					<div class="img-icon"></div>
					<div>历史记录</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import sscHistory1Vue from '@/components/sscHistory1.vue'
	import sscHistory2Vue from '@/components/sscHistory2.vue'
	import sscHistory3Vue from '@/components/sscHistory3.vue'
	export default{
		components: {
			sscHistory1Vue,
			sscHistory2Vue,
			sscHistory3Vue,
		},
		data(){
			return {
				menuType:0,
				gameType:0,
				gameIndex:0,
				chipIndex:0,
				menuList: [
					{
						name: 'Help',
						icon: 'Help',
					},
					{
						name: 'region',
						icon: 'region',
					},
					{
						name: 'Volume',
						icon: 'Volume',
					},
					{
						name: 'Setting',
						icon: 'Setting',
					},
				]
			}
		},
		methods:{
			toggleGameType(index){
				// this.gameType = index;
			}
		}
	}
</script>

<style lang="scss">
	.center-box{
		@apply flex-1 flex flex-col md:pb-0 md:w-[65%] 2xl:w-6/12;
		.live-box{
			background: #BCFF2F;;
			@apply flex w-full aspect-[2/1] shrink-0 overflow-auto;
		}
	}
	.history-box{
		height: calc(100vh - 40px - 66px);
		@apply flex w-full overflow-hidden scrollbar-none shrink-0 2xl:aspect-[2/1] 2xl:h-[inherit];
	}
	.user-bet-history{
		background: #2CABE7;;
		@apply flex w-full h-full;
	}
	.main-box{
		background: theme('colors.bgColor3');
		flex: 1;
		@apply w-full h-auto md:flex flex-row md:justify-between md:overflow-hidden px-0 md:px-0;
		.action-box{
			@apply w-full mt-[10px] px-[5px] md:pb-[80px] 2xl:px-0 overflow-y-scroll scrollbar-none 2xl:overflow-hidden;
			.toggle-game{
				@apply w-full flex flex-row flex-nowrap justify-around 2xl:hidden;
				overflow-x: auto;
				.game-item{
					background: #424242;
					color:#fff;
					@apply flex shrink-0 justify-center flex-1 mx-[5px] h-[40px] items-center text-[12px];
				}
			}
			.chip-box-md{
				@apply w-full hidden md:w-[85%] lg:w-[50%] flex flex-row justify-between items-center px-2 float-right mt-4;
				.chip-item{
					background: #000;
					color: #929292;
					@apply size-[30px] md:size-[40px] rounded-full text-[12px] flex items-center justify-center;
				}
			}
			.clear-float{
				@apply hidden md:block clear-both;
			}
		}
		
	}
</style>