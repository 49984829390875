<template>
	<div class="page">
		<div class="container-box mx-auto aspect-auto h-screen 2xl:h-auto 2xl:aspect-[16/9]">
			<headertop></headertop>
			<div class="main-box">
				<!-- MD以上显示左侧菜单按钮 -->
				<div class="menu-box">
					<div class="game-time-box">
						50
					</div>
					<div class="username-box">
						HONEST
					</div>
					<div class="userMoney-box">
						<div class="unit-box">$</div>
						<div class="money-box">0.00</div>
					</div>
					<div class="game-menu">
						<!-- <div class="game-menu-item" :class="item == 1 ? 'active' : '' " v-for="item in 6">GAME1</div> -->
						<div class="game-menu-item" @click='toggleBetBox(28)'>28</div>
						<div class="game-menu-item" @click='toggleBetBox(17)'>17</div>
						<div class="game-menu-item" @click='toggleBetBox(12)'>12</div>
						<div class="game-menu-item" @click='toggleBetBox(10)'>10</div>
						<div class="game-menu-item" @click='toggleBetBox(4)'>4</div>
						<div class="game-menu-item" @click='toggleBetBox(3)'>3</div>
						<div class="game-menu-item" @click='toggleBetBox(2)'>2</div>
					</div>
				</div>
				<!-- 历史记录区域 -->
				<div class="center-box">
					<!-- <div class="live-box">
						<sscHistory1Vue></sscHistory1Vue>
					</div> -->
					
					<div class="action-box">
						<div class="action-box-pc hidden 2xl:flex">
							<!-- 通选 复选 -->
							<div class="check-box">
								<!-- <div class="check-row w-full">
									<div class="check-item w-full">通选</div>
								</div> -->
								<div class="check-row">
									<div class="check-item">1D</div>
									<div class="check-item">2D</div>
									<div class="check-item">3D</div>
									<div class="check-item">4D</div>
									<div class="check-item">5D</div>
								</div>
							</div>
							
							<!-- 投注预选区域 -->
							<div class="perselect-box 2xl:flex">
								<div class="perselect-item flex justify-center items-center text-[#ffffff]">
									<div class="">QUANTITY:</div>
									<div class="text-center text-[#BCFF2F] ml-[5px]">0</div>
								</div>
								<div class="perselect-item flex justify-center items-center text-[#ffffff]">
									<div class="">TICKET(S):</div>
									<div class="text-center text-[#BCFF2F] ml-[5px]">0</div>
								</div>
								<div class="perselect-item flex justify-center items-center text-[#ffffff]">
									<div class="">PRICE:</div>
									<div class="text-center text-[#BCFF2F] ml-[5px]">0</div>
								</div>
							</div>
							<div class="chip-box-pc 2xl:flex">
								<img class="chip-icon w-[37px] h-[25px]" src="@/assets/icons/Vector.png" alt="" />
								<div class="chip-item" v-for="item in 5">{{item}}</div>
							</div>
						</div>
						<div class="toggle-game">
							<div class="game-item" @click='toggleBetBox(28)'>28</div>
							<div class="game-item" @click='toggleBetBox(17)'>17</div>
							<div class="game-item" @click='toggleBetBox(12)'>12</div>
							<div class="game-item" @click='toggleBetBox(10)'>10</div>
							<div class="game-item" @click='toggleBetBox(4)'>4</div>
							<div class="game-item" @click='toggleBetBox(3)'>3</div>
							<div class="game-item" @click='toggleBetBox(2)'>2</div>
						</div>
						<div class="check-box-md">
							<div class="check-row">
								<div class="check-item">通选</div>
								<div class="check-item">1D</div>
								<div class="check-item">2D</div>
								<div class="check-item">3D</div>
								<div class="check-item">4D</div>
								<div class="check-item">5D</div>
							</div>
						</div>
						<!-- 赔率 -->
						<div class="game-detial 2xl:hidden">
							<div class="odds-box">
								<span class="mr-3">$2/TICKET</span>
								<span>TICKETS LIMT:20K</span>
							</div>
							<div class="money-box">
								$0.00
								<span class="icon size-5"></span>
							</div>
						</div>
						<!-- PC版 -->
						<div class="game-detial hidden 2xl:flex">
							<div class="odds-box">
								<span class="mr-3">$2/TICKET</span>
							</div>
							<div class="odds-box">
								<span>TICKETS LIMT:20K</span>
							</div>
						</div>
						<!-- 操作 -->
						<div class="operate-box-pc">
							<div class="operate-item-box">
								RESET
							</div>
							<div class="operate-item-box">
								SET-
							</div>
							<div class="operate-item-box">
								SET+
							</div>
							<div class="operate-item-box">
								CONFI
							</div>
						</div>
						<div class="chip-box-md">
							<div class="chip-icon w-[37px] h-[25px]" style="border: 1px solid red;"></div>
							<div class="chip-item" v-for="item in 9">{{item}}</div>
						</div>
						<div class="clear-float"></div>
						
						
						<div class="action-row-28" v-if="boxIndex == 28">
							<div class="action-item-box" v-for="item in 28">
								{{item}}
							</div>
						</div>
						<div class="action-row-17" v-if="boxIndex == 17">
							<div class="action-item-box" v-for="item in 17">
								{{item}}
							</div>
						</div>
						<div class="action-row-12" v-if="boxIndex == 12">
							<div class="action-item-box" v-for="item in 12">
								{{item}}
							</div>
						</div>
						<div class="action-row-10" v-if="boxIndex == 10">
							<div class="action-item-box" v-for="item in 10">
								{{item}}
							</div>
						</div>
						<div class="action-row-4 2xl:mt-[60px]" v-if="boxIndex == 4">
							<div class="action-item-box" v-for="item in 4">
								{{item}}
							</div>
						</div>
						<div class="action-row-3 2xl:mt-[60px]" v-if="boxIndex == 3">
							<div class="action-item-box" v-for="item in 3">
								{{item}}
							</div>
						</div>
						
						<div class="action-row-2 2xl:mt-[60px]" v-if="boxIndex == 2">
							<div class="action-item-box" v-for="item in 2">
								{{item}}
							</div>
						</div>
						
						
						<!-- 投注数量 -->
						<div class="chip-box mt-[10px]">
							<img class="chip-icon w-[37px] h-[25px]" src="@/assets/icons/Vector.png" alt="" />
							<div class="chip-item" v-for="item in 9">{{item}}</div>
						</div>
						<div class="clear-float"></div>
						<!-- 投注预选区域 -->
						<div class="perselect-box xl:flex 2xl:hidden">
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">QUANTITY:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">0</div>
							</div>
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">TICKET(S):</div>
								<div class="flex-1 text-center text-[#BCFF2F]">0</div>
							</div>
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">PRICE:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">0</div>
							</div>
						</div>
						<div class="perselect-box xl:flex 2xl:hidden">
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1 ">HIT PRIZE:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">0</div>
							</div>
						</div>
						<!--下注按钮等-->
						<div class="operate-box">
							<div class="operate-item-box">
								RESET
							</div>
							<div class="operate-item-box">
								SET-
							</div>
							<div class="operate-item-box">
								SET+
							</div>
							<div class="operate-item-box">
								CONFI
							</div>
						</div>
					</div>
					
					<div class="bet-all-box md:hidden">
						<div class="bet-all-title">CURRENT PURCHASE RECORD</div>
						<div class="bet-box">
							<div class="bet-item" v-for="item in 20">
								{{item}}
							</div>
						</div>
					</div>
					
					<!-- <div class="bet-box">
						预选返奖区域
					</div> -->
				</div>
				<!-- 右侧历史记录 -->
				<div class="right-box">
					<div class="bet-all-box">
						<div class="bet-all-title">CURRENT PURCHASE RECORD</div>
						<div class="bet-box">
							<div class="bet-item" v-for="item in 20">
								{{item}}
							</div>
						</div>
					</div>
					<div class="buy-history">
						<div class="buy-history-title">已购彩票</div>
						<div class="buy-box">
							<div class="buy-item" v-for="item in 10">
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">1</div>
									<div class="buy-item-title-group-item">$100</div>
									<div class="buy-item-title-group-item">$999</div>
								</div>
							</div>
							
							<div class="buy-item">
								<div class="buy-item-title">购彩内容</div>
								<div class="buy-item-name-box">
									<div class="buy-item-name-item">购彩总数</div>
									<div class="buy-item-name-item">总价</div>
									<div class="buy-item-name-item">奖金最大</div>
								</div>
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">购彩总数</div>
									<div class="buy-item-title-group-item">总价</div>
								</div>
								<div class="buy-item-content-group">
									<div class="buy-item-content-group-item">100</div>
									<div class="buy-item-content-group-item">$999</div>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="bet-box">
						预选返奖区域
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import sscHistory1Vue from '@/components/sscHistory2.vue'
	export default{
		components: {
			sscHistory1Vue
		},
		data(){
			return {
				boxIndex:0,
				menuList: [
					{
						name: 'Help',
						icon: 'Help',
					},
					{
						name: 'region',
						icon: 'region',
					},
					{
						name: 'Volume',
						icon: 'Volume',
					},
					{
						name: 'Setting',
						icon: 'Setting',
					},
				]
			}
		},
		methods:{
			toggleBetBox(index){
				this.boxIndex = index;
			}
		}
	}
</script>

<style lang="scss">
	
</style>