<template>
	<div class="page flex flex-col w-full h-full pt-1" style="background-color: #1a1a1a">
		<div class="overflow-auto h-full w-full min-h-full ssc-history" ref="scrollContainer" id="nav">
			<table class="min-w-full border-collapse table-auto w-full min-h-full relative">
				<thead class="sticky top-0 z-50 bg-[#1a1a1a]">
					<tr class="flex top-0 columns-auto mb-1">
						<!-- 固定的表头行 -->
						<th class="tr-line1">
							<span class="th-span">期号</span>
						</th>
						<th class="tr-line2">
							<span class="th-span">中奖结果</span>
						</th>
						<th class="th-header grow" v-show="d>=1"><span class="th-span">第一位</span></th>
						<th class="th-header grow" v-show="d>=2"><span class="th-span">第二位</span></th>
						<th class="th-header grow" v-show="d>=3"><span class="th-span">第三位</span></th>
						<th class="th-header grow" v-show="d>=4"><span class="th-span">第四位</span></th>
						<th class="th-header grow" v-show="d>=5"><span class="th-span">第五位</span></th>
						<th class="th-header"><span class="th-span">对比</span></th>
						<th class="th-header" v-for="item in continuousKey"><span class="th-span">{{ item }}</span></th>


						<!-- 根据需要添加更多列 -->
					</tr>
				</thead>
				<tbody>
					<block class="block list-centent">
						<tr class="flex mt-1" v-for="(item, key) in list">
							<!-- 固定的左侧两列 -->
							<td class="tr-line1">
								<span class="th-span">{{ issue[key] }}</span>
							</td>
							<td class="tr-line2 gap-0.5 flex">
								<span
									class="inline-block w-5 h-5 text-center text-sm leading-5 font-semibold rounded-xl bg-[#2fbcff]"
									v-for="(i, idx) in winningNumbers[key]" :key="idx"
									:class="i>=5 ? 'bg-[#ff2fbc]':'bg-[#2fbcff]' ">{{ i }}</span>
							</td>
							<td class="td-header-2" v-for="i in setNumber[key]">
								<div class="h-full" :class="
                    isNumber(i[0]) ? 'bg-[#424242] text-[#888]' : 'bg-[#2fbcff]'
                  ">
									{{ i[0] }}
								</div>
								<div class="h-full" :class="
                    isNumber(i[1]) ? 'bg-[#424242] text-[#888]' : 'bg-[#ff2fbc]'
                  ">
									{{ i[1] }}
								</div>
							</td>
							<td class="td-header">
								<span class="th-span">{{ contrast[key] }}</span>
							</td>
							<td class="td-header" v-for="item in contrastData[key]">
								<span class="block h-full text-sm leading-6 rounded-sm" :class="
                    isNumber(item) ? 'bg-[#424242] text-[#888]' : 'bg-[#2fbcff]'
                  ">{{ item }}</span>
							</td>
						</tr>
					</block>
					<block class="sticky w-full block left-0 z-30 bottom-0 pt-0.5 bg-[#1a1a1a]">
						<tr class="flex sticky left-0 mt-1 w-full">
							<!-- 固定的左侧两列 -->
							<td class="tr-line3" colspan="2">
								<span class="th-span">预选区</span>
							</td>
							<td class="td-header-2" v-for="i in preSelection['number']">
								<div class=" h-full" :class=" i[0]=='S' || i[0]=='O' ? 'bg-[#2fbcff]':'bg-[#434343]'">
									{{ i[0] }}
								</div>
								<div class=" h-full" :class=" i[1]=='B' || i[1]=='E' ? 'bg-[#ff2fbc]':'bg-[#434343]'">
									{{ i[1] }}
								</div>
							</td>
							<td class="td-header">
								<span class="th-span">{{ preSelection['ratio'] }}</span>
							</td>
							<td class="td-header" v-for="i in preSelection['contrast']">
								<span class="th-span-ratio"
									:class=" !isNumber(i) ? 'bg-[#2fbcff]':'bg-[#434343]'">{{ i }}</span>
							</td>
						</tr>
						<tr class="flex mt-1">
							<td class="tr-line3" colspan="2">
								<span class="th-span">出现总次数</span>
							</td>
							<td class="td-header-2" v-for="item in totalOccurrences">
								<div class="bg-[#434343] h-full">{{ item[0] }}</div>
								<div class="bg-[#434343] h-full">{{ item[1] }}</div>
							</td>
							<td class="td-header"></td>
							<td class="td-header" v-for="item in compareTotalOccurrences">
								<span class="th-span">{{ item }}</span>
							</td>
						</tr>
						<tr class="flex mt-1">
							<td class="tr-line3" colspan="2">
								<span class="th-span">连续出现(最大次数)</span>
							</td>

							<td class="td-header-2" v-for="item in continuousOccurrence">
								<div class="bg-[#434343] h-full">{{ item[1] }}</div>
								<div class="bg-[#434343] h-full">{{ item[0] }}</div>
							</td>

							<td class="td-header"></td>
							<td class="td-header" v-for="item in compareConsecutiveOccurrences">
								<span class="th-span">{{ item }}</span>
							</td>

							<!-- 根据需要添加更多列 -->
						</tr>
						<tr class="flex mt-1">
							<!-- 固定的左侧两列 -->
							<td class="tr-line3" colspan="2">
								<span class="th-span">连续不出现(最大次数)</span>
							</td>
							<td class="td-header-2" v-for="item in continuousOccurrence">
								<div class="bg-[#434343] h-full">{{ item[0] }}</div>
								<div class="bg-[#434343] h-full">{{ item[1] }}</div>
							</td>
							<td class="td-header"></td>
							<td class="td-header" v-for="item in compareDiscontinuousOccurrences">
								<span class="th-span">{{ item }}</span>
							</td>

							<!-- 根据需要添加更多列 -->
						</tr>
					</block>

					<!-- 更多行 -->
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['list', 'd'],
		data() {
			return {
				list: [
					'5409100016-34586',
					'5409100017-54186',
					'5409100018-56096',
					'5409100019-66019',
					'5409100020-06522',
					'5409100021-45881',
					'5409100022-99095',
					'5409100023-82034',
					'5409100024-81040',
					'5409100025-72393',
					'5409100026-47935',
					'5409100027-11032',
					'5409100028-94813',
					'5409100029-54602',
					'5409100030-85670',
					'5409100031-72816',
					'5409100032-35525',
					'5409100033-38762',
					'5409100034-29893',
					'5409100035-25868',
					'5409100036-16875',
					'5409100037-13567',
					'5409100038-37363',
					'5409100039-43752',
					'5409100040-86830',
					'5409100041-50563',
					'5409100042-07000',
					'5409100043-72472',
					'5409100044-30525',
					'5409100045-92821',
					'5409100046-62317',
					'5409100047-59899',
					'5409100048-94543',
					'5409100049-68460',
					'5409100050-11357',
					'5409100051-75600',
					'5409100052-36337',
					'5409100053-97931',
					'5409100054-85593',
					'5409100055-18238',
					'5409100056-34097',
					'5409100057-67080',
					'5409100058-48948',
					'5409100059-64884',
					'5409100060-20316',
					'5409100061-48481',
					'5409100062-62568',
					'5409100063-53146',
					'5409100064-05210',
					'5409100065-25730',
					'5409100066-30249',
					'5409100067-53625',
					'5409100068-61377',
					'5409100069-37847',
					'5409100070-84987',
					'5409100071-93224',
					'5409100072-71598',
					'5409100073-21602',
					'5409100074-61234',
					'5409100075-28092',
					'5409100076-79555',
					'5409100077-02572',
					'5409100078-01982',
					'5409100079-98152',
					'5409100080-21216',
					'5409100081-90563',
					'5409100082-50461',
					'5409100083-72032',
					'5409100084-51981',
					'5409100085-89637',
					'5409100086-35729',
					'5409100087-40708',
					'5409100088-92232',
					'5409100089-98042',
					'5409100090-94375',
					'5409100091-21131',
					'5409100092-83190',
					'5409100093-47618',
					'5409100094-09306',
					'5409100095-12122',
					'5409100096-15210',
					'5409100097-40650',
					'5409100098-86983',
					'5409100099-77259',
					'5409100100-17705',
					'5409100101-56601',
					'5409100102-63730',
					'5409100103-75845',
					'5409100104-70544',
					'5409100105-64328',
					'5409100106-03636',
					'5409100107-52748',
					'5409100108-02993',
					'5409100109-01750',
					'5409100110-61148',
					'5409100111-75747',
					'5409100112-56237',
					'5409100113-49906',
					'5409100114-32117',
					'5409100115-31941',
				],
				setNumber: [], //单个号码
				issue: [], //期号
				winningNumbers: [], //开奖号码
				totalOccurrences: [], //出现总次数
				continuousOccurrence: [], //连续出现次数
				contrast: [], //对比
				contrastData: [], //对比数据
				compareTotalOccurrences: [], //对比出现总次数
				compareConsecutiveOccurrences: [], //对比连续出现最大值
				compareConsecutiveOccurrencesData: [], //对比连续出现最大值 暂存
				compareDiscontinuousOccurrences: [], //对比不连续出现最大值
				preSelection: [], //预选区
				type: 'bigSmall', //bigSmall
				d: 5, //5星
				ratioTitle: [],
				continuousKey: [] //对比的值
			};
		},
		mounted() {
			this.initRatio();
			this.initHistory(this.list);
			this.updatePreSelection();
			this.$nextTick(() => {
				this.scrollToBottom();
			});
			this.scrollInit();
		},
		methods: {
			// 初始化与绑定监听事件方法
			scrollInit() {
				// 获取要绑定事件的元素
				const nav = document.getElementById("nav")
				var flag; // 鼠标按下
				var downX; // 鼠标点击的x下标
				var scrollLeft; // 当前元素滚动条的偏移量
				nav.addEventListener("mousedown", function(event) {
					flag = true;
					downX = event.clientX; // 获取到点击的x下标
					scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
				});
				nav.addEventListener("mousemove", function(event) {
					if (flag) { // 判断是否是鼠标按下滚动元素区域
						var moveX = event.clientX; // 获取移动的x轴
						var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
						this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
						console.log(scrollX)
					}
				});
				// 鼠标抬起停止拖动
				nav.addEventListener("mouseup", function() {
					flag = false;
				});
				// 鼠标离开元素停止拖动
				nav.addEventListener("mouseleave", function(event) {
					flag = false;
				});
			},
			scrollToBottom() {
				const container = this.$refs.scrollContainer;
				container.scrollTop = container.scrollHeight;
			},
			initRatio() {
				switch (this.d) {
					case 5:
						this.continuousKey = ['5:0', '4:1', '3:2', '2:3', '1:4', '0:5'];
						break;
					case 4:
						this.continuousKey = ['4:0', '3:1', '2:2', '1:3', '0:4'];
						break;
					case 3:
						this.continuousKey = ['3:0', '2:1', '1:2', '0:3'];
						break;
					case 2:
						this.continuousKey = ['2:0', '1:1', '0:2'];
						break;
					case 1:
						this.continuousKey = ['1:0', '0:1'];
						break;
				}
				for (let i = 0; i < this.continuousKey.length; i++) {
					this.compareTotalOccurrences.push(0);
					this.compareConsecutiveOccurrences.push(0);
					this.compareConsecutiveOccurrencesData.push(0);
					this.compareDiscontinuousOccurrences.push(0);
				}
				for (let i = 0; i < this.d; i++) {
					this.totalOccurrences.push([0, 0]);
					this.continuousOccurrence.push([0, 0]);
				}
			},
			initHistory(data) {
				for (let i = 0; i < data.length; i++) {
					let one = 0;
					let two = 0;
					let itemData = data[i];
					let jsonData = itemData.split('-');
					let preJsonData = i > 0 ? data[i - 1].split('-') : data[0].split('-'); // 上一条数据
					this.issue.push(jsonData[0]); // 期号
					let number = jsonData[1]; // 开奖号码
					this.winningNumbers.push(number);

					let currentSetNumber = []; // 当前号码设置 "大" 或 "小"
					for (let i = 0; i < this.d; i++) {
						currentSetNumber.push([0, 0]);
					}
					number = number.substring(0, this.d)
					for (let s = 0; s < number.length; s++) {
						let numberItem = Number(number[s]);
						// 判断当前值是 "小" 还是 "大"
						if (this.type == 'oddEven') {
							if (numberItem % 2 != 0) {
								currentSetNumber[s][0] = '单'; // 标记为 "小"
								if (Number(preJsonData[1][s]) % 2 == 0) {
									currentSetNumber[s][1] = 1;
								} else {
									currentSetNumber[s][1] =
										i > 0 ? this.setNumber[i - 1][s][1] + 1 : 1;
								}
								one++; //对比暂存
								//最大出现
								if (this.continuousOccurrence[s][1] < currentSetNumber[s][1]) {
									this.continuousOccurrence[s][1] = currentSetNumber[s][1];
								}
								this.totalOccurrences[s][0]++; //小 出现总次数++
							} else {
								currentSetNumber[s][1] = '双'; // 标记为 "大"
								if (Number(preJsonData[1][s]) % 2 != 0) {
									currentSetNumber[s][0] = 1;
								} else {
									currentSetNumber[s][0] =
										i > 0 ? this.setNumber[i - 1][s][0] + 1 : 1;
								}
								two++; //对比暂存
								//最大出现
								if (this.continuousOccurrence[s][0] < currentSetNumber[s][0]) {
									this.continuousOccurrence[s][0] = currentSetNumber[s][0];
								}
								this.totalOccurrences[s][1]++; //大 出现总次数++
							}
						} else {
							if (numberItem < 5) {
								currentSetNumber[s][0] = '小'; // 标记为 "小"
								if (Number(preJsonData[1][s]) >= 5) {
									currentSetNumber[s][1] = 1;
								} else {
									currentSetNumber[s][1] =
										i > 0 ? this.setNumber[i - 1][s][1] + 1 : 1;
								}
								one++; //对比暂存
								//最大出现
								if (this.continuousOccurrence[s][1] < currentSetNumber[s][1]) {
									this.continuousOccurrence[s][1] = currentSetNumber[s][1];
								}
								this.totalOccurrences[s][0]++; //小 出现总次数++
							} else {
								currentSetNumber[s][1] = '大'; // 标记为 "大"
								if (Number(preJsonData[1][s]) < 5) {
									currentSetNumber[s][0] = 1;
								} else {
									currentSetNumber[s][0] =
										i > 0 ? this.setNumber[i - 1][s][0] + 1 : 1;
								}
								two++; //对比暂存
								//最大出现
								if (this.continuousOccurrence[s][0] < currentSetNumber[s][0]) {
									this.continuousOccurrence[s][0] = currentSetNumber[s][0];
								}
								this.totalOccurrences[s][1]++; //大 出现总次数++
							}
						}
					}
					this.setNumber.push(currentSetNumber);
					this.contrast.push(one + ':' + two);
				}
				//对比记录
				for (let i = 0; i < this.contrast.length; i++) {
					let zcdb = [];
					for (let j = 0; j < this.continuousKey.length; j++) {
						if (this.contrast[i] == this.continuousKey[j]) {
							zcdb.push(this.continuousKey[j]);
						} else {
							let ro =
								i > 0 ?
								this.isNumber(this.contrastData[i - 1][j]) ?
								this.contrastData[i - 1][j] + 1 :
								1 :
								1;
							zcdb.push(ro);
						}
					}
					this.contrastData.push(zcdb);
				}
				//出现总次数
				for (let i = 0; i < this.contrastData.length; i++) {
					for (let j = 0; j < this.contrastData[i].length; j++) {
						if (!this.isNumber(this.contrastData[i][j])) {
							this.compareTotalOccurrences[j]++;
						} else {
							if (
								this.compareDiscontinuousOccurrences[j] < this.contrastData[i][j]
							) {
								this.compareDiscontinuousOccurrences[j] = this.contrastData[i][j];
							}
						}
						//处理最大连续出现次数
						if (i > 0) {
							if (
								!this.isNumber(this.contrastData[i][j]) ||
								this.contrastData[i][j] == this.contrastData[i - 1][j]
							) {
								this.compareConsecutiveOccurrencesData[j]++;
								if (
									this.compareConsecutiveOccurrencesData[j] >
									this.compareConsecutiveOccurrences[j]
								) {
									this.compareConsecutiveOccurrences[j] =
										this.compareConsecutiveOccurrencesData[j];
								}
							} else {
								this.compareConsecutiveOccurrencesData[j] = 0;
							}
						} else {
							if (!this.isNumber(this.contrastData[i][j])) {
								this.compareConsecutiveOccurrencesData[j]++;
							}
						}
					}
				}
				this.initPreSelection();
			},
			initPreSelection() {
				let numberArea = [];
				let contrastArea = [];
				let lastSetNumber = this.setNumber[this.setNumber.length - 1];
				for (const key in lastSetNumber) {
					let v1 = this.isNumber(lastSetNumber[key][0]) ? parseInt(lastSetNumber[key][0]) + 1 : 1
					let v2 = this.isNumber(lastSetNumber[key][1]) ? parseInt(lastSetNumber[key][1]) + 1 : 1
					numberArea.push([v1, v2])
				}
				let lastcontrastData = this.contrastData[this.contrastData.length - 1];
				for (const key in lastcontrastData) {
					let v = this.isNumber(lastcontrastData[key]) ? parseInt(lastcontrastData[key]) + 1 : 1
					contrastArea.push(v)
				}
				this.preSelection = {
					number: numberArea,
					ratio: '0:0',
					contrast: contrastArea
				};
			},
			updatePreSelection(number = 'B') {
				number = number.split(',');
				console.log(number.length)
				let one = 0;
				let two = 0;
				// preSelection
				for (let i = 0; i < number.length; i++) {
					console.log(this.preSelection.number[i])
					if (this.type == 'oddEven') {
						if (number[i] == 'S') {
							this.preSelection.number[i][0] = 'O'
							one++;
						} else {
							this.preSelection.number[i][1] = 'E'
							two++;
						}
					} else {
						if (number[i] == 'S') {
							this.preSelection.number[i][0] = 'S'
							one++;
						} else {
							this.preSelection.number[i][1] = 'B'
							two++;
						}
					}
				}
				this.preSelection.ratio = one + ':' + two;


				for (let i = 0; i < this.continuousKey.length; i++) {
					if (this.continuousKey[i] == this.preSelection.ratio) {

						this.preSelection.contrast[i] = this.continuousKey[i];
					}
				}

			},
			isNumber(value) {
				return typeof value === 'number' && !isNaN(value);
			},
		},
	};
</script>

<style lang="scss"></style>