<template>
	<div class="page">
		<div class="container-box mx-auto aspect-auto h-screen 2xl:h-auto 2xl:aspect-[16/9]">
			<headertop :isHome="true"></headertop>
			<div class="main-box">
				<!-- 用户信息 -->
				<div class="user-box">
					<div class="userName">用户昵称</div>
					<div class="userMoney">$0</div>
				</div>
				<!-- MD以上显示左侧菜单按钮 -->
				<div class="menu-box">
					<div class="username-box">
						HONEST
					</div>
					<div class="userMoney-box">
						<div class="unit-box">$</div>
						<div class="money-box">0.00</div>
					</div>
					<div class="game-menu">
						<div class="game-menu-item home" v-for="item in gameList" @click='navToGame(item.name)'>
							<div class="icon ">
								<!-- <img src="" alt="" /> -->
							</div>
							<div class="game-name">
								 {{item.name}}
							</div>
						</div>
					</div>
				</div>
				<!-- 游戏列表 -->
				<div class="game-list">
					<div class="game-item" v-for="(item,index) in gameList" @click='navToGame(item.name)'>
						<div class="game-item-title">
							<div class="flex flex-row items-center">
								<div class="icon-box"></div>
								<div class="game-name ml-4">{{item.name}}</div>
							</div>
							<div class="hot-box flex flex-row">
								<!-- <div class="hot-icon">O</div> -->
								<div class="hot-text">{{index}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				gameList:[
					{
						icon:"",
						name:"5Stars",
					},
					// {
					// 	icon:"",
					// 	name:"mark6",
					// },
					// {
					// 	icon:"",
					// 	name:"lucky3d",
					// },
					// {
					// 	icon:"",
					// 	name:"texas",
					// },
					// {
					// 	icon:"",
					// 	name:"zodiacgo",
					// },
					// {
					// 	icon:"",
					// 	name:"slotlotto",
					// },
				]
			}
		},
		methods:{
			navToGame(name){
				this.$router.push({
					path:name
				});//跳转到游戏页面
			}
		}
	}
</script>

<style lang="scss">
	.menu-box{
		// @apply hidden md:block h-full md:w-[200px];
	}
	.user-box{
		background: #000000;
		height: 40px;
		@apply mx-[10px] rounded-sm overflow-hidden flex justify-between items-center px-3 my-3 md:hidden;
		.userName,.userMoney{
			color: #ffffff;
		}
	}
</style>