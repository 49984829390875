<template>
	<div class="page flex flex-col w-full h-full pt-1" style="background-color: #1a1a1a">
		<div class="overflow-auto h-full w-full min-h-full ssc-history-star" ref="scrollContainer" id="nav">
			<table class="min-w-full border-collapse table-auto min-h-full relative ">
				<thead class="sticky top-0 z-50  bg-[#1a1a1a]">
					<tr class="flex  top-0  h-11 leading-11 header-th mb-1">
						<!-- 固定的表头行 -->
						<th class="tr-line1 ">
							<span class="th-span">期号</span>
						</th>
						<th class="tr-line2">
							<span class="th-span">中奖结果</span>
						</th>
						<th class="th-header grow grid grid-rows-2">
							<span class="th-span">第一位</span>
							<div class="columns-10 gap-0.5">
								<span class="th-span" v-for="n in 10">{{ n-1 }}</span>
							</div>
						</th>
						<th class="th-header grow grid grid-rows-2" v-show="d>=2">
							<span class="th-span">第二位</span>
							<div class="columns-10 gap-0.5">
								<span class="th-span" v-for="n in 10">{{ n-1 }}</span>
							</div>
						</th>
						<th class="th-header grow grid grid-rows-2" v-show="d>=3">
							<span class="th-span">第三位</span>
							<div class="columns-10 gap-0.5">
								<span class="th-span" v-for="n in 10">{{ n-1 }}</span>
							</div>
						</th>
						<th class="th-header grow grid grid-rows-2" v-show="d>=4">
							<span class="th-span">第四位</span>
							<div class="columns-10 gap-0.5">
								<span class="th-span" v-for="n in 10">{{ n-1 }}</span>
							</div>
						</th>
						<th class="th-header grow grid grid-rows-2" v-show="d>=5">
							<span class="th-span">第五位</span>
							<div class="columns-10 gap-0.5">
								<span class="th-span" v-for="n in 10">{{ n-1 }}</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<block class="block list-centent">
						<tr class="flex mt-1 " v-for="item,key in list">
							<!-- 固定的左侧两列 -->
							<td class="tr-line1">
								<span class="th-span">{{ issue[key] }}</span>
							</td>
							<td class="tr-line2 gap-0.5 flex">
								<span
									class="inline-block w-5 h-5 text-center text-sm leading-5 font-semibold rounded-xl bg-[#2fbcff]"
									v-for="(i, idx) in winningNumbers[key]" :key="idx"
									:class="i>=5 ? 'bg-[#ff2fbc]':'bg-[#2fbcff]' ">{{ i }}</span>
							</td>
							<td class="td-header-10 columns-10 gap-0.5" v-for="item in dataNumbers[key]">
								<span class="th-span-number" :class="
                    [isNumber(n) ? 'bg-[#424242] text-[#888]' : 'bg-[#2fbcff]',!isNumber(n) && n.replace('*','')>=5 ? 'bg-[#ff2fbc]':'bg-[#2fbcff]']
                  " v-for="n in item">{{ !isNumber(n) ? n.replace('*',''):n }}</span>
							</td>
						</tr>
					</block>
					<block class="block  w-full sticky left-0 z-30 bottom-0 pt-0.5 bg-[#1a1a1a]">
						<tr class="flex   mt-1 ">
							<!-- 固定的左侧两列 -->
							<td class="tr-line3" colspan="2">
								<span class="th-span">预选区</span>
							</td>
							<td class="td-header-10 columns-10 gap-0.5" v-for="item in preSelection">
								<span class="th-span-number"
									:class="!isNumber(i) ?  i.replace('*','')>=5 ? 'bg-[#ff2fbc]':'bg-[#2fbcff]':'bg-[#434343]'"
									v-for="i in item">{{ isNumber(i) ? i : i.replace('*','') }}</span>
							</td>
						</tr>
						<tr class="flex mt-1 ">
							<!-- 固定的左侧两列 -->
							<td class="tr-line3" colspan="2">
								<span class="th-span">出现总次数</span>
							</td>
							<td class="td-header-10 columns-10 gap-0.5" v-for="item in continuousOccurrence">
								<span class="th-span" v-for="n in item">{{ n }}</span>
							</td>

						</tr>
						<tr class="flex mt-1 ">
							<!-- 固定的左侧两列 -->
							<td class="tr-line3" colspan="2">
								<span class="th-span">连续出现(最大次数)</span>
							</td>

							<td class="td-header-10 columns-10 gap-0.5" v-for="item in consecutiveOccurrences">
								<span class="th-span" v-for="n in item">{{ n }}</span>
							</td>

						</tr>
						<tr class="flex mt-1">
							<!-- 固定的左侧两列 -->
							<td class="tr-line3" colspan="2">
								<span class="th-span">连续不出现(最大次数)</span>
							</td>
							<td class="td-header-10 columns-10 gap-0.5" v-for="item in discontinuousOccurrences">
								<span class="th-span" v-for="n in item">{{ n }}</span>
							</td>


						</tr>
					</block>

					<!-- 更多行 -->
				</tbody>

			</table>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['list', 'd'],
		data() {
			return {
				list: [
					'5409100016-34586',
					'5409100017-54186',
					'5409100018-56096',
					'5409100019-66019',
					'5409100020-06522',
					'5409100021-45881',
					'5409100022-99095',
					'5409100023-82034',
					'5409100024-81040',
					'5409100025-72393',
					'5409100026-47935',
					'5409100027-11032',
					'5409100028-94813',
					'5409100029-54602',
					'5409100030-85670',
					'5409100031-72816',
					'5409100032-35525',
					'5409100033-38762',
					'5409100034-29893',
					'5409100035-25868',
					'5409100036-16875',
					'5409100037-13567',
					'5409100038-37363',
					'5409100039-43752',
					'5409100040-86830',
					'5409100041-50563',
					'5409100042-07000',
					'5409100043-72472',
					'5409100044-30525',
					'5409100045-92821',
					'5409100046-62317',
					'5409100047-59899',
					'5409100048-94543',
					'5409100049-68460',
					'5409100050-11357',
					'5409100051-75600',
					'5409100052-36337',
					'5409100053-97931',
					'5409100054-85593',
					'5409100055-18238',
					'5409100056-34097',
					'5409100057-67080',
					'5409100058-48948',
					'5409100059-64884',
					'5409100060-20316',
					'5409100061-48481',
					'5409100062-62568',
					'5409100063-53146',
					'5409100064-05210',
					'5409100065-25730',
					'5409100066-30249',
					'5409100067-53625',
					'5409100068-61377',
					'5409100069-37847',
					'5409100070-84987',
					'5409100071-93224',
					'5409100072-71598',
					'5409100073-21602',
					'5409100074-61234',
					'5409100075-28092',
					'5409100076-79555',
					'5409100077-02572',
					'5409100078-01982',
					'5409100079-98152',
					'5409100080-21216',
					'5409100081-90563',
					'5409100082-50461',
					'5409100083-72032',
					'5409100084-51981',
					'5409100085-89637',
					'5409100086-35729',
					'5409100087-40708',
					'5409100088-92232',
					'5409100089-98042',
					'5409100090-94375',
					'5409100091-21131',
					'5409100092-83190',
					'5409100093-47618',
					'5409100094-09306',
					'5409100095-12122',
					'5409100096-15210',
					'5409100097-40650',
					'5409100098-86983',
					'5409100099-77259',
					'5409100100-17705',
					'5409100101-56601',
					'5409100102-63730',
					'5409100103-75845',
					'5409100104-70544',
					'5409100105-64328',
					'5409100106-03636',
					'5409100107-52748',
					'5409100108-02993',
					'5409100109-01750',
					'5409100110-61148',
					'5409100111-75747',
					'5409100112-56237',
					'5409100113-49906',
					'5409100114-32117',
					'5409100115-31941',
				],
				issue: [], //期号
				winningNumbers: [], //开奖号码
				dataNumbers: [], //数据号码
				continuousOccurrence: [], //出现总次数
				consecutiveOccurrences: [], //连续出现最大值
				consecutiveOccurrencesData: [], //连续出现最大值暂存
				discontinuousOccurrences: [], //不连续出现最大值
				preSelection: [], //预选区
				d: 5, //预选区
			};
		},
		mounted() {
			for (let i = 0; i < this.d; i++) {
				this.continuousOccurrence.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
				this.consecutiveOccurrences.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
				this.consecutiveOccurrencesData.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
				this.discontinuousOccurrences.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
			}
			this.initHistory(this.list);
			this.updatePreSelection();
			this.$nextTick(() => {
				this.scrollToBottom();
			});
			this.scrollInit();
		},
		methods: {
			scrollInit() {
				// 获取要绑定事件的元素
				const nav = document.getElementById("nav")
				var flag; // 鼠标按下
				var downX; // 鼠标点击的x下标
				var scrollLeft; // 当前元素滚动条的偏移量
				nav.addEventListener("mousedown", function(event) {
					flag = true;
					downX = event.clientX; // 获取到点击的x下标
					scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
				});
				nav.addEventListener("mousemove", function(event) {
					if (flag) { // 判断是否是鼠标按下滚动元素区域
						var moveX = event.clientX; // 获取移动的x轴
						var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
						this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
						console.log(scrollX)
					}
				});
				// 鼠标抬起停止拖动
				nav.addEventListener("mouseup", function() {
					flag = false;
				});
				// 鼠标离开元素停止拖动
				nav.addEventListener("mouseleave", function(event) {
					flag = false;
				});
			},
			scrollToBottom() {
				const container = this.$refs.scrollContainer;
				container.scrollTop = container.scrollHeight;
			},
			initHistory(data) {
				for (let i = 0; i < data.length; i++) {
					let itemData = data[i];
					let jsonData = itemData.split('-');
					let preJsonData = i > 0 ? data[i - 1].split('-') : data[0].split('-'); // 上一条数据
					this.issue.push(jsonData[0]); // 期号
					let number = jsonData[1]; // 开奖号码
					this.winningNumbers.push(number);
					let numberItemData = [];
					number = number.substring(0, this.d)
					for (let j = 0; j < number.length; j++) {
						let numberItem = [];
						for (let k = 0; k < 10; k++) {

							if (k == number[j]) {
								numberItem.push('*' + k);
								this.continuousOccurrence[j][k]++;
								//处理最大连续出现次数
								if (preJsonData[1][j] == number[j]) {
									this.consecutiveOccurrencesData[j][k]++
									if (this.consecutiveOccurrencesData[j][k] > this.consecutiveOccurrences[j][k]) {
										this.consecutiveOccurrences[j][k] = this.consecutiveOccurrencesData[j][k]
									}
								} else {
									if (this.consecutiveOccurrences[j][k] == 0) {
										this.consecutiveOccurrences[j][k] = 1;
										this.consecutiveOccurrencesData[j][k] = 1;
									}
									if (this.consecutiveOccurrences[j][k] > 1) {
										this.consecutiveOccurrencesData[j][k] = 1;
									}
								}
							} else {
								let idx = 1;
								if (i > 0) {
									idx = this.dataNumbers[i - 1][j][k];
									idx = this.isNumber(idx) ? parseInt(idx) + 1 : 1;
									numberItem.push(idx); //this.dataNumbers[i-1][j]++
								} else {
									numberItem.push(idx);
								}
								//最大出现
								if (this.discontinuousOccurrences[j][k] < idx) {
									this.discontinuousOccurrences[j][k] = idx;
								}
							}
						}
						numberItemData.push(numberItem)

					}
					this.dataNumbers.push(numberItemData);
				}
				console.log(this.dataNumbers)
				this.initPreSelection();
			},
			initPreSelection() {
				let lastDataNumber = this.dataNumbers[this.dataNumbers.length - 1];
				for (let i = 0; i < lastDataNumber.length; i++) {
					let numberData = [];
					for (let j = 0; j < lastDataNumber[i].length; j++) {
						numberData.push(this.isNumber(lastDataNumber[i][j]) ? lastDataNumber[i][j] + 1 : 1)
					}
					this.preSelection.push(numberData);
				}
				console.log(this.preSelection)
			},
			updatePreSelection(number = [
				[2, 5, 6, 9, 8]
			]) {
				for (let i = 0; i < number.length; i++) {
					for (let j = 0; j < number[i].length; j++) {
						this.preSelection[i][number[i][j]] = '*' + number[i][j];
					}
				}
				console.log(this.preSelection)
			},
			isNumber(value) {
				return typeof value === 'number' && !isNaN(value);
			},
		},
	};
</script>