<template>
  <router-view/>
</template>

<script>
	export default {
		mounted() {
			this.initWebSocket();
		},
		methods:{
			 initWebSocket() {//webSocket初始化
				  let path='';
				  //这里是关键，可以判断当前环境是生产环境还是开发环境，开发环境加测试域名即可调试，生产环境由于代码要放到服务器获取本地域名即可
				  if(process.env.NODE_ENV === 'development') {
					path =  'demo.honestlotto.com'; //这里改成你测试的域名
				  } else {
					path = window.location.host;
				  }
				  //如果是wss协议,这里path一定要域名才能正常连接 ip+端口的方式不行
				  const wsuri = 'wss://' + path + "/ws?t=ssc&token=" + this.$route.query.token;
				  this.websock = new WebSocket(wsuri);
				  this.websock.onopen = function(event) {
					 console.log("WebSocket:已连接");
					 console.log(event);
				  };
				  this.websock.onmessage = function(event) {
					console.log("WebSocket:消息",event);
					console.log(event);
				  };
				  this.websock.onerror = function(event) {
					console.log("WebSocket:发生错误 ");
					console.log(event);
				  };
				  this.websock.onclose = function(event) {
					console.log("WebSocket:已关闭");
					console.log(event);
				  };
			   }
		}
	}
</script>
<style lang="scss">
	@import './css/base.scss';
	@import './css/components.scss';
	@import './css/utilities.scss';
	@import './css/history.scss';
	
	@tailwind components;
	@tailwind utilities;
	@font-face {
	  font-family:'Koulen';
	  src: url("@/assets/font/Koulen.ttf") format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	
	*{
		user-select: none; /* 禁止选中 */
		-webkit-user-select: none; /* 针对 Safari 浏览器 */
		-moz-user-select: none; /* 针对 Firefox 浏览器 */
		-ms-user-select: none; /* 针对 IE 浏览器 */
		cursor: default; /* 鼠标样式变为默认 */
	}
	body{
		background: theme('colors.bgColor4');
	}

</style>
