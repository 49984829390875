<template>
	<div class="header">
		<img class="logo" src="@/assets/logo.png" alt="" />
		
		<div class="menu-icon size-7 block xl:hidden" style="background: red;">
			
		</div>
		<div class="action-box hidden xl:flex items-center">
			<div class="action-item" @click="navToAction(item.name)" v-for="(item,index) in menuList" :class="(item.name == 'black' && isHome) ? 'hidden' : ''">
				{{item.name}}-{{isHome}}
				<!-- v-if="item.name == 'back' && isHome == true" -->
			</div>
		</div>
	</div>
	<!-- 顶部占位符 -->
	<div class="header-slot"></div>
</template>

<script>
	export default{
		props:{
			isHome:{
				type:Boolean,
				default:false
			}
		},
		data(){
			return {
				menuList:[
					{
						icon:"right",
						name:"black",
					}
				]
			}
		},
		methods:{
			navToAction(name){
				if(name == 'black'){
					this.$router.go(-1)
				}
			}
		}
	}
</script>

<style lang="scss">
	.action-item{
		color: #fff;
	}
</style>